.training-sheet-item-enter {
  opacity: 0;
}

.training-sheet-item-enter-active {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.training-sheet-item-exit-active {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.training-sheet-item-exit-done {
  display: none;
}
